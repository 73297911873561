<template>
  <div class="history-page">
    <div class="history-page__table">
      <div class="history-page__table-header-row">
        <!-- <pre>{{ searchServices[0] }}</pre> -->
        <span
          class="history-page__table-header-row_date history-page__table-cell history-page__table-cell_date"
        >
          <!-- <b-field> -->
          <div class="history-page__input history-page__input_date">
            <Datepicker
              class="history-page__datepicker"
              :value="form.received_at"
              :bootstrap-styling="true"
              placeholder="Select date"
              format="dd-MM-yyyy"
              @selected="value => form.received_at = value"
            />

            <div class="history-page__input-clear" @click="form.received_at = null">
              <b-icon v-if="form.received_at" pack="fas" icon="times" size="is-small"></b-icon>
            </div>
          </div>
          <!-- @selected="doSomethingInParentComponentFunction" -->
          <!-- <input
            class="history-page__input history-page__input_date"
            v-model="form.received_at"
            type="date"
          >-->

          <!--  -->
          <!-- <b-datepicker
              v-model="form.received_at"
              class="custom-datapicker"
              size="is-small"
              icon-pack="fas"
              placeholder="DATE"
              icon="calendar-today"
          ></b-datepicker>-->
          <!-- </b-field> -->

          <i class="icon-days" @click="sortDateHelper">
            <AppSortArrow class="icon-sort" :value="sortDate == 'date'"/>
          </i>
          <!-- <i class="icon-sort" @click="sortDateHelper">
            <b-icon
              :class="{ 'rotate': sortDate == 'date' }"
              pack="fas"
              icon="chevron-down"
              size="is-small"
            ></b-icon>
          </i>-->
          <!-- </i> -->
          <!-- <b-icon class="custom-datapicker__icon" pack="fas" icon="calendar-today" size="is-small"></b-icon> -->
        </span>
        <span class="history-page__table-cell history-page__table-cell_service">
          <b-field>
            <b-input
              v-model="form.service_name"
              custom-class="history-page__input"
              placeholder="Service"
              type="search"
              size="is-small"
              icon-pack="fas"
              icon="search"
            ></b-input>
          </b-field>
        </span>
        <span class="history-page__table-cell history-page__table-cell_number">
          <b-field>
            <b-input
              v-model="form.number"
              custom-class="history-page__input"
              placeholder="Number"
              type="search"
              size="is-small"
              icon-pack="fas"
              icon="search"
            ></b-input>
            <!-- <b-autocomplete
              v-model="number"
              placeholder="Number"
              :keep-first="keepFirst"
              :open-on-focus="openOnFocus"
              :data="historyOrders"
              field="number"
              size="is-small"
              icon-pack="fas"
              icon="search"
              custom-class="history-page__input"
              @select="option => selected = option"
            ></b-autocomplete>-->
          </b-field>
        </span>
        <div
          @click="sortDayHelper"
          class="history-page__table-header-title history-page__table-cell_days"
        >
          <span>Days</span>
          <i class="icon-sort">
            <AppSortArrow class="icon-sort" :value="sortDays ? sortDays == '-days' : null"/>
          </i>
          <!-- <i class="icon-sort">
            <b-icon
              :class="{ 'rotate': sortDays == '-days' }"
              pack="fas"
              icon="chevron-down"
              size="is-small"
            ></b-icon>
          </i>-->
        </div>
        <span
          class="history-page__table-header-title history-page__table-cell history-page__table-cell_price"
        >Price</span>
        <span
          class="history-page__table-header-title history-page__table-cell history-page__table-cell_text"
        >Text</span>
        <!--  <span 
          @click="sortStatusHelper" 
          class="history-page__table-header-title history-page__table-header-title_white history-page__table-cell_status" >
          Status
          <i class="icon-sataus">
            <b-icon
              :class="{ 'rotate': rotateStatusIcon }"
              pack="fas"
              icon="chevron-down"
              size="is-small">
            </b-icon>
          </i>
        </span>-->
        <span
          class="history-page__table-header-title history-page__table-cell history-page__table-cell_status history-page__table-cell_status_header"
        >
          <b-select v-model="form.status" placeholder="STATUS">
            <option
              size="is-small"
              @change="sortStatusHelper"
              v-for="option in dataStatus"
              :value="option.id"
              :key="option.id"
            >{{ option.status }}</option>
          </b-select>
        </span>
        <span
          class="history-page__table-header-title history-page__table-cell history-page__table-cell_action"
        >Action</span>
      </div>

      <div class="history-page__table-body-row" ref="content">
        <!-- <pre>{{searchServices}}</pre> -->
        <div class="history-page__table-row" v-for="(item, index) in searchServices" :key="index">
          <div class="history-page__table-cell history-page__table-cell_date">
            <span>{{ item.received_at }}</span>
          </div>
          <span class="history-page__table-cell history-page__table-cell_service">
            <div class="service">
              <img
                class="history-page__icon"
                v-if="item.service.icon"
                :src="item.service.icon"
                :alt="item.service.name"
              >
              <p class="service__name">{{ item.service.name }}</p>
            </div>
          </span>
          <span class="history-page__table-cell history-page__table-cell_number">
            <span class="card__list-icon">
              <span class="flag-icon" :class="`flag-icon-${item.number.flag}`"></span>
            </span>
            {{ item.number.number }}
          </span>
          <p class="history-page__table-cell history-page__table-cell_days">
            <b-tooltip
              :label="`Guarantee on the mobile number ${item.days <= 14 ? item.days : '14+'} days`"
              :class="{'custom-toltip-green': item.days >= 14, 
                                'custom-toltip-yellow': item.days >= 6,
                                'custom-toltip-red': item.days <= 5}"
              position="is-left"
              type="is-dark"
              animated
              multilined
            >
              <span class="number-day">
                <span
                  :class="[{
                          'green': item.days >= 14,
                          'yellow':  item.days >= 6, 
                          'red': item.days <= 5
                        }]"
                >{{ `${item.days <= 14 ? item.days : '14+'}` }}</span>
                <sup>{{" "}}i</sup>
              </span>
            </b-tooltip>
          </p>
          <span class="history-page__table-cell history-page__table-cell_price">€{{ item.price }}</span>
          <span class="history-page__table-cell history-page__table-cell_text">{{ item.sms_text }}</span>
          <span
            class="history-page__table-cell history-page__table-cell_status"
            :class="{'green': item.number_still_active,  
                    'red': !item.number_still_active }"
          >{{ item.status | statusWord }}</span>
          <span class="history-page__table-cell history-page__table-cell_action">
            <!-- @click="buySMS(item)" -->
            <button class="buy-btn" v-if="item.number_still_active" @click="rebuyItem = item">Rebuy</button>
            <button class="clear-btn" v-else>Clean</button>
          </span>
        </div>
      </div>
    </div>

    <Pagination
      class="history-page__pagination"
      :total="count"
      :perPage="limit"
      :current.sync="currentPage"
    />

    <b-modal :active.sync="rebuyItem" :width="520" scroll="keep">
      <div class="order-modal" v-if="rebuyItem">
        <h1 class="order-modal__title">Rebuy</h1>

        <ul class="order-modal__order-list">
          <li class="order-modal__order-list-item">
            <span class="order-modal__order-info">Service:</span>

            <div class="card__content">
              <span class="card__list-icon card__list-icon_order" v-if="rebuyItem.service.icon">
                <img :src="rebuyItem.service.icon" :alt="rebuyItem.service.name">
              </span>
              <span class="order-value">{{rebuyItem.service.name}}</span>
            </div>
          </li>
          <!-- 
          <li class="order-modal__order-list-item">
            <span class="order-modal__order-info">country:</span>

            <div class="card__content">
              <span class="card__list-icon card__list-icon_order">
                <span class="flag-icon" :class="`flag-icon-${rebuyItem.number.flag}`"></span>
              </span>
            </div>
          </li>-->

          <li class="order-modal__order-list-item">
            <span class="order-modal__order-info">Number:</span>

            <div class="card__content">
              <span class="order-value">
                <span class="flag-icon" :class="`flag-icon-${rebuyItem.number.flag}`"></span>
                {{rebuyItem.number.number}}
              </span>
            </div>
          </li>

          <li class="order-modal__order-list-item order-modal__order-list-item_total">
            <span class="order-modal__order-info">total</span>

            <div class="card__content">
              <span class="order-value price">€{{rebuyItem.price}}</span>
            </div>
          </li>
        </ul>

        <button class="order-modal__button" @click="buySMS(rebuyItem)">Buy</button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

import AppSortArrow from "@/components/app/root/AppSortArrow";
import Pagination from "@/components/Pagination";

import Datepicker from "vuejs-datepicker";

import iconMixins from "../mixins/decorIcons";
import dateFilter from "../filters/date.filters";

import { HistoryService } from "../services/history.services";
import { GetSMSService } from "../services/dashboard.services";

// import { domen } from "@/utils/constants";

export default {
  mixins: [iconMixins],

  components: {
    AppSortArrow,
    Pagination,

    Datepicker
  },

  filters: {
    daysDecor(value) {
      return "hello";

      if (+value >= 14) {
        return "14+";
      } else {
        return value;
      }
    }
  },

  data: () => ({
    form: {
      received_at: null,
      service_name: null,
      number: null,
      status: null
    },

    servicesSearch: "",
    value: null,
    keepFirst: false,
    openOnFocus: false,
    number: "",
    selected: null,
    dataStatus: [
      { id: "active", status: "Active" },
      { id: "deleted", status: "Deleted" }
    ],
    historyOrders: null,
    limit: 10,
    offset: 0,
    count: 0,
    currentPage: 1,

    // rotateDayIcon: false,
    sortDays: null,
    sortDate: "-date",

    rotateStatusIcon: false,
    sortParam: "",
    orderDay: -1,
    orderStatus: -1,

    heigtWindow: 0,
    scrollElem: null,

    rebuyItem: null
  }),

  computed: {
    searchServices: function() {
      if (this.servicesSearch) {
        let servicesSearch = this.servicesSearch;
        return this.historyOrders.filter(function(services) {
          return services.service.includes(servicesSearch);
        });
      } else if (this.number) {
        return this.historyOrders.filter(option => {
          return option.number.toString().includes(this.number);
        });
      } else if (this.value) {
        let servicesDate = this.value;
        let date = servicesDate.getDate();
        let month = servicesDate.getMonth() + 1;
        let year = servicesDate.getFullYear();
        let fullDate =
          date.toString() + ".0" + month.toString() + "." + year.toString();
        return this.historyOrders.filter(function(item) {
          return item.date.includes(fullDate);
        });
      } else {
        return this.historyOrders;
      }
    },

    formHash() {
      return `${this.form.received_at}-${this.form.service_name}-${
        this.form.number
      }-${this.form.status}`;
    }
  },

  mounted() {
    this.$watch(
      "formHash",
      next => {
        this.currentPage = 1;
        this.historyOrders = [];
        this.loadHistory();
      },
      {
        immediate: true
      }
    );

    this.$watch("currentPage", next => {
      this.loadHistory();
    });

    // this.heigtWindow = window.innerHeight;

    // this.scrollElem = this.$refs.content;

    // this.scrollElem.addEventListener("scroll", e => {
    //   if (
    //     this.scrollElem.scrollTop + this.scrollElem.clientHeight >=
    //     this.scrollElem.scrollHeight
    //   ) {
    //     if (this.count - (this.offset + this.limit) > 0) {
    //       this.offset = this.offset + this.limit;
    //       this.loadHistory();
    //     }
    //   }
    // });
  },

  methods: {
    sortDayHelper() {
      if (this.sortDays === null) {
        this.sortDays = "days";
      } else {
        this.sortDays = this.sortDays === "-days" ? "days" : "-days";
      }

      this.offset = 0;
      this.currentPage = 1;

      this.loadHistory();
    },

    sortDateHelper() {
      this.sortDate = this.sortDate === "-date" ? "date" : "-date";
      this.offset = 0;
      this.currentPage = 1;

      this.loadHistory();
    },

    sortStatusHelper: function() {
      this.sortParam = "status";
      this.rotateStatusIcon = !this.rotateStatusIcon;
      this.orderStatus = this.orderStatus * -1;
      let order = this.orderStatus;
      this.historyOrders.sort(function(d1, d2) {
        return d1.status > d2.status ? 1 : -1 * order;
      });
    },

    getDaysTest(days) {
      if (days >= 14) return "14+";
      else return days;
    },

    async loadHistory() {
      try {
        const date =
          this.form.received_at &&
          moment(this.form.received_at).format("YYYY-MM-DD");

        let sort = this.sortDays ? `${this.sortDays},` : "";

        sort += this.sortDate;

        console.log(sort);

        const response = await HistoryService.getHistory({
          limit: this.limit,
          offset: (this.currentPage - 1) * this.limit,
          ...this.form,
          status: this.form.status && this.form.status === "active",
          received_at_after: date,
          received_at_before: date,
          sort
        });

        //   ...this.form,
        //   received_at:
        //     this.form.received_at &&
        //     moment(this.form.received_at).format("YYYY-MM-DD")
        // });

        this.count = response.count;

        this.historyOrders = response.results;
      } catch (error) {
        /* this.errorMessage = error.response
        this.showErrorMsg() */
        throw error;
      }
    },

    async buySMS(data) {
      let buyData = {
        service: data.service.id,
        phone_number: data.number.id
      };

      try {
        const response = await GetSMSService.buySms(buyData);

        this.$toasted.show(`Your purchase is successful.`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 5000,
          className: "success-notification"
        });

        this.$router.push("/orders");
      } catch (error) {
        // this.errorSms = error.message;
        this.$toasted.show(`Error. ${error.message.generic_error[0]}`, {
          theme: "toasted-primary",
          position: "top-right",
          duration: 5000,
          className: "danger-notification"
        });
      }
    }
  },
  filters: {
    statusWord(value) {
      if (value == 0) {
        return "Deleted";
      } else {
        return "Active";
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.history-page {
  &__pagination {
    margin: 15px 0;
  }
}
</style>

